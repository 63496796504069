import React from 'react'
import styled from 'styled-components'
import { Landing } from './Landing'
import { Route, Routes } from 'react-router-dom'
import { NotFound } from './NotFound'

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #0a69ff;
  background-image: linear-gradient(rgba(10, 105, 255, 0.9), rgba(10, 105, 255, 0.9)),
    radial-gradient(circle at 0%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
    url('https://assets-global.website-files.com/61ae538b58e14595c9148d42/61daf1d2ee276fdcb6892da7_cute-cats.png');
  background-position:
    0 0,
    0 0,
    50%;
  background-repeat: repeat, repeat, repeat;
  background-size: auto, auto, auto;
  background-attachment: scroll, scroll, scroll;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  display: flex;

  @media (max-width: 768px) {
    background-size: auto, auto, 1500px;
  }
`

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppContainer>
            <Landing />
          </AppContainer>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
