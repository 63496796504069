import styled from 'styled-components'

export const NotFoundContainer = styled.div`
  display: flex;
  width: 90%;
  height: 100vh;
  margin-bottom: 0px;
  padding-top: 5%;
  padding-bottom: 5%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  margin: 0 auto;
  box-sizing: border-box;
`

export const TextNumber = styled.p`
  font-family: 'Roboto Mono', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TextContainer = styled.div``

export const NotFoundTitle = styled.h1`
  margin-top: 0px;
  margin-bottom: 0.4em;
  font-size: 3.125rem;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: -0.02em;
  font-family: 'Graphik', sans-serif;
`

export const NotFoundSubtitle = styled.p`
  margin-bottom: 1em;
  font-size: 1.1rem;
  line-height: 1.7;
  font-family: 'Graphik', sans-serif;
`
