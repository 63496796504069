import styled from 'styled-components'

export const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 258px;
  }
`

export const LandingLogo = styled.img`
  height: 250px;
  width: 250px;
`

export const LandingTitle = styled.h1`
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  color: #fff;
  margin-bottom: 2vh;
  margin-top: 20px;
  font-size: 50px;
  line-height: 1.3;

  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center;
  }
`

export const LandingDescription = styled.p`
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  color: #fff;
  margin: 0 0 2vh;
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    margin-bottom: 4vh;
    font-size: 14px;
  }
`

export const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1vw;
  margin-top: 1vh;

  @media (max-width: 768px) {
    gap: 2vw;
  }
`

export const SocialMediaLink = styled.a`
  text-decoration: none;
`

export const SocialMediaLogo = styled.div`
  width: 80px;
  height: 80px;
  color: #fff;

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
`

export const LandingFTMLink = styled.a`
  z-index: 998;
  height: 3vh;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-size: 14px;
  line-height: 20px;
`
