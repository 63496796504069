import React from 'react'
import {
  LandingWrapper,
  LandingLogo,
  LandingTitle,
  LandingDescription,
  SocialMediaWrapper,
  SocialMediaLink,
  SocialMediaLogo,
  LandingFTMLink,
} from './styles'

import { TelegramIcon } from 'icons/TelegramIcon'
import { TwitterIcon } from 'icons/TwitterIcon'
import { InstagramIcon } from 'icons/InstagramIcon'

export const Landing = () => {
  return (
    <LandingWrapper>
      <LandingLogo src="https://assets-global.website-files.com/61ae538b58e14595c9148d42/61dab728ee276f4c00880667_White-500.png" />
      <LandingTitle>
        <strong>$FANTY is Way Cuter Than Shiba</strong>
      </LandingTitle>
      <LandingDescription>Coming Soon.</LandingDescription>
      <SocialMediaWrapper>
        <SocialMediaLink href="https://t.me/Fanty_Official" target="_blank" rel="noreferrer noopener">
          <SocialMediaLogo>
            <TelegramIcon />
          </SocialMediaLogo>
        </SocialMediaLink>
        <SocialMediaLink href="https://twitter.com/fantytweet" target="_blank" rel="noreferrer noopener">
          <SocialMediaLogo>
            <TwitterIcon />
          </SocialMediaLogo>
        </SocialMediaLink>
      </SocialMediaWrapper>
      <LandingFTMLink>Contract Address: 0x1E71834F7f1d17a62C960506c71A169999E04C86</LandingFTMLink>
    </LandingWrapper>
  )
}
