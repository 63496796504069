import React from 'react'
import { NotFoundContainer, TextNumber, TextContainer, NotFoundTitle, NotFoundSubtitle } from './styles'

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <TextNumber>404</TextNumber>
      <TextContainer>
        <NotFoundTitle>Page not found</NotFoundTitle>
        <NotFoundSubtitle>The page you are looking for doesn&lsquo;t exist or has been moved.</NotFoundSubtitle>
      </TextContainer>
      <div></div>
    </NotFoundContainer>
  )
}
